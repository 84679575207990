header {
    height: 100vh;
    padding-top: 7rem;
    
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

/* CTA */
.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* Header socials */
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

/* 
.header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}
 */

/* image */
.me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 30rem;
    height: 34rem;
    left: calc(50% - 15rem);
    margin-top: 4rem;
    border-radius: 14rem  14rem 0 0;
    
    padding: 5rem 1.5rem 1.5rem 1.5rem;
    position: absolute;
}

.me img {
    
    border-radius: 10rem 10rem 0 0;
    overflow: hidden;
    width: 100%;
    height: 85%;
    

}

/* scrool down */
.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

/* Media Queries (Medium Devices)*/
@media screen and (max-width: 1024px) {
   
}

/* Media Queries (Small Devices)*/
@media screen and (max-width: 600px) {
    header {
        height: 100vh;
    }

    .me {
        width: 20rem;
        height: 24rem;
        left: calc(50% - 10rem);
        margin-top: 4rem;
        border-radius: 8rem 8rem 0 0;
        overflow: hidden;
        padding: 2rem 1.5rem 1.5rem 1.5rem;
        position: absolute;
    }

    .me img {
        border-radius: 7rem 7rem 0 0;
        overflow: hidden;
        width: 100%;
        height: 85%;
    }

    .header__socials, .scroll__down {
        display: none;
    }
}